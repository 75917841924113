import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import axios from "axios";
import {Dialog} from "primereact/dialog";
import MetricsmeasureFormComponent from "./MetricsmeasureFormComponent";

const MetricsmeasureListComponent = (props) => {

  const [metricsmeasure, setMetricsmeasure] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderMetricsmeasureList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/metrics_measure/" + window.App.state.propsMetrics.id + "/?" + JSON.stringify(lazyParams).replace('"multiSortMeta":[],', ''),
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setMetricsmeasure(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteMetricsmeasure = (rowData) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_HOST + "/metrics/" + rowData.id,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                type="button"
                onClick={() => {
                  window.App.setState({
                    dialogMetricsmeasureForm: true,
                    propsMetricsmeasure: rowData
                  })
                }}/>
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteMetricsmeasure(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }


  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderMetricsmeasureList === true])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={metricsmeasure} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        dataKey="id"
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="unitName" header="Mértékegység" sortable filter filterPlaceholder="Mértékegység"></Column>
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          <Button type="button" label="Új mennyiségi egység hozzáadása" icon="pi pi-plus" className="p-button-success"
                  onClick={() => {
                    window.App.setState({
                      dialogMetricsmeasureForm: true,
                      propsMetricsmeasure: null
                    })
                  }}
          />
        </div>
      </div>
      <Dialog visible={window.App.state.dialogMetricsmeasureForm}
              style={{width: '750px'}}
              header="Mennyiségi egység hozzáadása"
              modal
              onHide={() => {
                window.App.setState({dialogMetricsmeasureForm: false})
              }}>
        <MetricsmeasureFormComponent  />
      </Dialog>
    </>
  )
}
export default withTranslation('common')(MetricsmeasureListComponent);
