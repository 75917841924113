import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog"

import ProductionitemListService from "../../services/productionitem/ProductionitemListService";
import ProductionitemFormComponent from "./ProductionitemFormComponent";
import ProductionitemStatusesService from "../../services/productionitem/ProductionitemStatusesService";
import {Sidebar} from "primereact/sidebar";
import FulfillmentFormComponent from "../fulfillment/FullfillmentFormComponent";

const ProductionitemListComponent = (props) => {

  const [productionitems, setProductionitems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'position',
    sortOrder: -1,
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProductionitemList: false})
      let _lazyParams = {
        ...lazyParams,
        filters: {
          projectItem: {
            value: Number(props.projectitemId)
          }
        }
      }
      ProductionitemListService(JSON.stringify(_lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProductionitems(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteProductionitem = (rowData) => {
    // ProductionitemDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    dialogProductionitemUpdate: true,
                    productionitemFormMethod: 'update',
                    productionitemRowData: rowData
                  })
                }}/>
      </React.Fragment>
    );
  }

  const [statuses, setStatuses] = useState([]);
  const status = (rowData) => {
    if (statuses && statuses[0]) {
      let status = statuses.filter(item => item.id === rowData.status)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }

  useEffect(() => {
    ProductionitemStatusesService()
      .then(response => {
        setStatuses(response.data)
      })
      .catch(error => {

      })
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderProductionitemList === true])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={productionitems} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        dataKey="id"
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        <Column field="serial" header="Sorszám"></Column>
        <Column field="status" header="Státusz" sortable body={status}></Column>
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="externalReference" header="Külső hivatkozás" sortable filter filterPlaceholder="Külső hivatkozás"></Column>
        <Column field="quantity" header="Mennyiség" className={"p-text-right"} alignHeader="right" body={(rowData) => {
          return (
            <>
              {rowData.quantity} {rowData.unitName}
            </>
          )
        }}></Column>
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          <Button type="button" label="Új feladat"
                  icon={"pi pi-plus"}
                  loading={loading}
                  onClick={() => {
                    window.App.setState({
                      dialogProductionitem: true,
                      productionitemRowData: null,
                      productionitemFormMethod: 'create',
                    })
                  }}
                  className="p-button-success"/>
          <Dialog visible={window.App.state.dialogProductionitem}
                  style={{width: '750px'}}
                  header="Új feladat"
                  modal
                  onHide={() => {
                    window.App.setState({
                      dialogProductionitem: false
                    })
                  }}>
              <ProductionitemFormComponent projectitemId={props.projectitemId} />
          </Dialog>
        </div>
      </div>
      <Dialog visible={window.App.state.dialogProductionitemUpdate}
              style={{width: '750px'}}
              header="Feladat módosítása"
              modal
              onHide={() => {
                window.App.setState({
                  dialogProductionitemUpdate: false
                })
              }}>
        <ProductionitemFormComponent method="put" initialValues={window.App.state.productionitemRowData}/>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(ProductionitemListComponent);
