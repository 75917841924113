import React, {useState, useEffect} from 'react';
import axios from "axios";
import {withTranslation} from 'react-i18next';
import moment from "moment";

const TracklogListComponent = (props) => {

  const [measures, setMeasures] = useState([]);
  const [tracklogs, setTracklogs] = useState([]);

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/metrics/" +  props.metricsId,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setMeasures(response.data.measures);
        if (props && props.metricsId) {
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_HOST + "/track_log/" + props.metricsId,
            params: {},
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
              'App-Network': localStorage.getItem("networkId")
            }
          })
            .then(response => {
              setTracklogs(response.data.items);
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [window.App.state.rerenderTracklogList === true])

  return (

    <>
      {tracklogs && tracklogs[0] ? (
        <table className={"w-100"}>
          <tr>
            <th>
              Projekt
            </th>
            <th>
              Létrehozva
            </th>
            {measures && measures[0] &&
              <>
                {measures.map((item, index) => {
                  return(
                    <th>
                      {item.name}
                    </th>
                  )
                })}
              </>
            }
          </tr>
          {tracklogs.map((itemTracklog, index) => {
            return (
              <tr>
                <td>
                  {itemTracklog.projectName}
                </td>
                <td>
                  {moment.utc(itemTracklog.createdAt).local().format('YYYY-MM-DD HH:mm')}
                </td>


                {measures.map((measureItem, index) => {
                  let value = itemTracklog.measures.filter(item => item.id === Number(index + 1))
                  if(value[0] && value[0] != undefined) {
                    return (
                      <td>
                        {value[0].value}
                      </td>
                    )
                  } else {
                    return (
                      <td>
                        &nbsp;
                      </td>
                    )
                  }
                })}


              </tr>
            )
          })}
        </table>
      ) : (
        <>Nincs adat</>
      )}
    </>

  )
}
export default withTranslation('common')(TracklogListComponent);
