import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import PurchaseorderRequestItemsAddedService from "../../services/purchaseorder/PurchaseorderRequestItemsAddedService";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import PurchaseorderDownloadService from "../../services/purchaseorder/PurchaseorderDownloadService";
import download from "js-file-download";
import moment from "moment";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import PurchaseorderRequestItemsUpdateService
  from "../../services/purchaseorder/PurchaseorderRequestItemsUpdateService";
import PurchaseorderStatusChangeTenderService
  from "../../services/purchaseorder/PurchaseorderStatusChangeTenderService";
import PurchaseorderShowService from "../../services/purchaseorder/PurchaseorderShowService";
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {AutoComplete} from "primereact/autocomplete";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import ProductListService from "../../services/product/ProductListService";
import currencyFormatter from "currency-formatter";
import PurchaseorderItemReplacementService from "../../services/purchaseorder/PurchaseorderItemReplacementService";

const PurchaseorderFeerequestComponent = (props) => {

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [dialogProductReplacement, setDialogProductReplacement] = useState(false);
  const [products, setProducts] = useState([]);
  const dt = useRef(null);

  // requested items
  const [requestItemsTarget, setRequestItemsTarget] = useState([]);
  const [loadingRequestItemsTarget, setLoadingRequestItemsTarget] = useState(false);
  const [totalRecordsRequestItemsTarget, setTotalRecordsRequestItemsTarget] = useState(0);
  const [rowsRequestItemsTarget, setRowsRequestItemsTarget] = useState(10);
  const [lazyParamsRequestItemsTarget, setLazyParamsRequestItemsTarget] = useState({
    first: 0,
    rows: rowsRequestItemsTarget,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutRequestItemsTarget = null;
  const loadLazyDataRequestItemsTarget = () => {
    setLoadingRequestItemsTarget(true);
    if (loadLazyTimeoutRequestItemsTarget) {
      clearTimeout(loadLazyTimeoutRequestItemsTarget);
    }
    loadLazyTimeoutRequestItemsTarget = setTimeout(() => {
      PurchaseorderRequestItemsAddedService(props.orderId, JSON.stringify(lazyParamsRequestItemsTarget))
        .then(response => {
          setTotalRecordsRequestItemsTarget(response.data.total_item_count);
          setRequestItemsTarget(response.data.items);
          setLoadingRequestItemsTarget(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageRequestItemsTarget = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsTarget, ...event};
    setLazyParamsRequestItemsTarget(_lazyParams);
  }
  const onSortRequestItemsTarget = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsTarget, ...event};
    setLazyParamsRequestItemsTarget(_lazyParams);
  }
  const onFilterRequestItemsTarget = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsTarget, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsRequestItemsTarget(_lazyParams);
  }
  const pagerRequestItemsTarget = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsRequestItemsTarget(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  //qunatity filter
  const [quantityFilterSelectedFrom, setQuantityFilterSelectedFrom] = useState(null);
  const [quantityFilterSelectedUntil, setQuantityFilterSelectedUntil] = useState(null);

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_') &&
        <Button icon="pi pi-sync" className="p-button-sm p-button p-mr-2"
                tooltip="Termékcsere"
                onClick={() => {
                  setOriginalItemId(rowData.id)
                  setDialogProductReplacement(true)
                }}/>
        }
      </React.Fragment>
    );
  }

  // items for replacement
  const [items, setItems] = useState([]);
  const [originalItemId, setOriginalItemId] = useState(0);
  const [loadingItems, setLoadingItems] = useState(false);
  const [totalRecordsItems, setTotalRecordsItems] = useState(0);
  const [rowsItems, setRowsItems] = useState(10);
  const [lazyParamsItems, setLazyParamsItems] = useState({
    first: 0,
    rows: rowsItems,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutItems = null;
  const loadLazyDataItems = () => {
    setLoadingItems(true);
    if (loadLazyTimeoutItems) {
      clearTimeout(loadLazyTimeoutItems);
    }
    loadLazyTimeoutItems = setTimeout(() => {
      ProductListService(JSON.stringify(lazyParamsItems))
        .then(response => {
          setTotalRecordsItems(response.data.total_item_count);
          setItems(response.data.items);
          setLoadingItems(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageItems = (event) => {
    let _lazyParams = {...lazyParamsItems, ...event};
    setLazyParamsItems(_lazyParams);
  }
  const onSortItems = (event) => {
    let _lazyParams = {...lazyParamsItems, ...event};
    setLazyParamsItems(_lazyParams);
  }
  const onFilterItems = (event) => {
    let _lazyParams = {...lazyParamsItems, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsItems(_lazyParams);
  }
  const pagerItems = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsItems(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadLazyDataRequestItemsTarget();
    loadLazyDataItems();
  }, [lazyParamsRequestItemsTarget, lazyParamsItems])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={requestItemsTarget} paginator first={lazyParamsRequestItemsTarget.first}
        totalRecords={totalRecordsRequestItemsTarget} onPage={onPageRequestItemsTarget}
        onSort={onSortRequestItemsTarget} sortField={lazyParamsRequestItemsTarget.sortField}
        sortOrder={lazyParamsRequestItemsTarget.sortOrder}
        onFilter={onFilterRequestItemsTarget} filters={lazyParamsRequestItemsTarget.filters}
        loading={loadingRequestItemsTarget} lazy
        paginatorTemplate={pagerRequestItemsTarget} rows={rowsRequestItemsTarget}
        emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        <Column field="projectName" header="Projekt" sortable filter filterPlaceholder="Projekt"></Column>
        <Column field="productName" header="Termék" sortable filter filterPlaceholder="Termék"></Column>
        <Column field="articleNumber" header="Cikkszám" sortable filter filterPlaceholder="Cikkszám"></Column>
        <Column field="quantity" header="Mennyiség" alignHeader="right" sortable filter filterPlaceholder="Mennyiség"
                className={"p-text-right"}
                filterElement={
                  <div className={"p-grid p-fluid"}>
                    <div className={"p-col-5"}>
                      <InputNumber value={quantityFilterSelectedFrom}
                                   id={quantityFilterSelectedFrom}
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   onChange={(e) => {
                                     setQuantityFilterSelectedFrom(e.value)
                                     clearTimeout(window.searchTimeout)
                                     window.searchTimeout = setTimeout(function () {
                                       dt.current.filter(e.value, 'quantityFrom', '');
                                     }, 500)
                                   }}
                      />
                    </div>
                    <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                      <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                    </div>
                    <div className={"p-col-5"}>
                      <InputNumber value={quantityFilterSelectedUntil}
                                   id={quantityFilterSelectedUntil}
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   onChange={(e) => {
                                     setQuantityFilterSelectedUntil(e.value)
                                     clearTimeout(window.searchTimeout)
                                     window.searchTimeout = setTimeout(function () {
                                       dt.current.filter(e.value, 'quantityUntil', '');
                                     }, 500)
                                   }}
                      />
                    </div>
                  </div>
                }
        ></Column>
        <Column field="unitName" header="M.e." sortable className={"p-text-right"}></Column>
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>
        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          {props.initialValues.status !== 'arrived' &&
          <>
            {loadingDownload === true ? (
              <>
                <Button type="button" label="Ajánlatkérő sablon" icon="pi pi-download" disabled loading
                        className="p-button-secondary p-mr-2"/>
              </>
            ) : (
              <>
                <Button type="button" label="Ajánlatkérő sablon" icon="pi pi-download"
                        className="p-button-secondary p-mr-2"
                        onClick={() => {
                          setLoadingDownload(true)
                          PurchaseorderDownloadService(props.orderId)
                            .then(response => {
                              download(response.data, localStorage.getItem('networkName')
                                + '_'
                                + 'ajánlat_sablon'
                                + '_'
                                + props.orderId
                                + '_'
                                + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                                + '.xlsx')
                              setLoadingDownload(false)
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              setLoadingDownload(false)
                            })
                        }}
                />
              </>
            )}
          </>
          }
          {props.initialValues.status === 'draft' &&
          <Button type="button" label="Összeállítás lezárása" className="p-button-warning p-mt-1" icon={"pi pi-check"}
                  onClick={() => {
                    PurchaseorderStatusChangeTenderService(props.initialValues.id)
                      .then(response => {
                        window.App.toastShow('success', 'Sekeres művelet!', 'Az összeállítás lázárásra került.');
                        PurchaseorderShowService(props.initialValues.id)
                          .then(response => {
                            window.App.setState({
                              props: response.data,
                              rerenderPurchaseorderShow: true
                            })
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })
                  }}
          />
          }
        </div>
      </div>
      <Dialog visible={dialogProductReplacement}
              style={{width: '980px'}}
              header="Termékcsere"
              modal
              onHide={() => {
                setDialogProductReplacement(false)
              }}>
        <DataTable
          emptyMessage="Nincs találat."
          value={items} paginator first={lazyParamsItems.first} totalRecords={totalRecordsItems} onPage={onPageItems}
          onSort={onSortItems} sortField={lazyParamsItems.sortField} sortOrder={lazyParamsItems.sortOrder}
          onFilter={onFilterItems} filters={lazyParamsItems.filters} loading={loadingItems} lazy
          paginatorTemplate={pagerItems} rows={rowsItems} emptyMessage="Nincs találat."
          autoLayout={true}
          filterDisplay="row"
          responsiveLayout="scroll"
          stripedRows
        >
          <Column field="name" header="Termék név" sortable filter filterPlaceholder="Név"></Column>
          <Column field="manufacturerName" header="Gyártó" sortable filter filterPlaceholder="Gyártó"></Column>
          <Column field="upc" header="Gyártó cikkszám" sortable filter filterPlaceholder="Gyártó cikkszám"></Column>
          <Column field="sku" header="Termék cikkszám" sortable filter filterPlaceholder="Termék cikkszám"></Column>
          <Column body={(rowData) => {
            return (
              <>
                <Button icon="pi pi-check" className="p-button-sm p-button-success p-mr-2"
                        tooltip="Csere erre"
                        onClick={() => {
                          PurchaseorderItemReplacementService(props.orderId, rowData.id, originalItemId)
                            .then(response => {
                              setDialogProductReplacement(false)
                              window.App.toastShow('success', 'Sikeres termékcsere');
                              loadLazyDataRequestItemsTarget(lazyParamsRequestItemsTarget)
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        }}/>
              </>
            )
          }}></Column>
        </DataTable>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(PurchaseorderFeerequestComponent);
