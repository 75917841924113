import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import moment from "moment";
import {addLocale} from "primereact/api";
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";

import PricelistitemFormService from '../../services/pricelistitem/PricelistitemFormService'
import MachineListService from "../../services/machine/MachineListService";
import TechnologyListService from "../../services/technology/TechnologyListService";
import EntrylocationemployeeListService from "../../services/entrylocationemployee/EntrylocationemployeeListService";
import EmployeeListService from "../../services/employee/EmployeeListService";
import CurrencyListService from "../../services/currency/CurrencyListService";
import {InputMask} from "primereact/inputmask";
import PricelistitemShowService from "../../services/pricelistitem/PricelistitemShowService";
import UnitListService from "../../services/unit/UnitListService";

const PricelistitemFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({
    currency: "HUF",
  });
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [units, setUnits] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      PricelistitemFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderPricelistitemList: true,
            sidebarPricelistitemUpdate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      PricelistitemFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderPricelistitemList: true,
            sidebarPricelistitemCreate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // machine autocomplete
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState();

  const searchMachine = (event) => {
    MachineListService(JSON.stringify({
      filters: {
        name: {
          value: event.query
        }
      }
    }))
      .then(response => {
        setMachines(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // technology autocomplete
  const [technologies, setTechnologies] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState();

  const searchTechnology = (event) => {
    TechnologyListService(JSON.stringify({
      filters: {
        name: {
          value: event.query
        }
      }
    }))
      .then(response => {
        setTechnologies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // employees autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const searchEmployees = (event) => {
    EmployeeListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          let _items = []
          Object.entries(response.data.items).map(function (item, index) {
            _items.push({
              name: item[1].name + " (" + item[1].companyName + ")",
              id: item[1].id
            })
          })
          setEmployees(_items)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })

  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    CurrencyListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setCurrencies(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    UnitListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setUnits(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      PricelistitemShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          setSelectedEmployee(response.data.employeeName)
          setSelectedTechnology(response.data.technologyName)
          setSelectedMachine(response.data.machineName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })

    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            {/*<div className={"p-col-12 p-xl-6"}>*/}
            {/*  <Field name="machine" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="machine"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Gép</label>*/}
            {/*      <span className="p-d-block">*/}
            {/*                <AutoComplete value={selectedMachine}*/}
            {/*                              id={"machine"}*/}
            {/*                              suggestions={machines}*/}
            {/*                              completeMethod={searchMachine}*/}
            {/*                              field={"name"}*/}
            {/*                              delay="1000"*/}
            {/*                              placeholder={"Keresés gépeléssel..."}*/}
            {/*                              className={classNames({'p-invalid': isFormFieldValid(meta)})}*/}
            {/*                              forceSelection*/}
            {/*                              onSelect={(e) => {*/}
            {/*                                setFormData({*/}
            {/*                                  ...formData,*/}
            {/*                                  machine: {id: e.value.id}*/}
            {/*                                })*/}
            {/*                              }}*/}
            {/*                              onChange={(e) => {*/}
            {/*                                setFormData({*/}
            {/*                                  ...formData,*/}
            {/*                                  machine: {id: null}*/}
            {/*                                })*/}
            {/*                                setSelectedMachine(e.value)*/}
            {/*                              }}*/}
            {/*                              dropdown*/}
            {/*                              onClear={(e) => {*/}
            {/*                                setSelectedMachine('')*/}
            {/*                              }}*/}
            {/*                />*/}
            {/*      </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            <div className={"p-col-12 p-xl-6"}>
              <Field name="technology" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="technology"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Technológia</label>
                  <span className="p-d-block">
                            <AutoComplete value={selectedTechnology}
                                          id={"technology"}
                                          suggestions={technologies}
                                          completeMethod={searchTechnology}
                                          field={"name"}
                                          delay="1000"
                                          forceSelection
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              technology: {id: e.value.id}
                                            })
                                          }}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              technology: {id: null}
                                            })
                                            setSelectedTechnology(e.value)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedTechnology('')
                                          }}
                            />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {/*<div className={"p-col-12 p-xl-6"}>*/}
            {/*  <Field name="employee" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="employee"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>*/}
            {/*      <span className="p-d-block">*/}
            {/*                  <AutoComplete value={selectedEmployee}*/}
            {/*                                id={"employee"}*/}
            {/*                                suggestions={employees}*/}
            {/*                                forceSelection*/}
            {/*                                completeMethod={searchEmployees}*/}
            {/*                                field={"name"}*/}
            {/*                                delay="1000"*/}
            {/*                                dropdown*/}
            {/*                                placeholder={"Keresés gépeléssel..."}*/}
            {/*                                className={classNames({'p-invalid': isFormFieldValid(meta)})}*/}
            {/*                                onSelect={(e) => {*/}
            {/*                                  setFormData({*/}
            {/*                                    ...formData,*/}
            {/*                                    employee: {id: e.value.id}*/}
            {/*                                  })*/}
            {/*                                }}*/}
            {/*                                onChange={(e) => {*/}
            {/*                                  setFormData({*/}
            {/*                                    ...formData,*/}
            {/*                                    employee: {id: null}*/}
            {/*                                  })*/}
            {/*                                  setSelectedEmployee(e.value)*/}
            {/*                                }}*/}
            {/*                                onClear={(e) => {*/}
            {/*                                  setSelectedEmployee('')*/}
            {/*                                }}*/}
            {/*                  />*/}
            {/*                </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            <div className={"p-col-12 p-xl-6"}>
              <Field name="currency" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="currency"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Pénznem</label>
                  <span className="p-d-block">
                    <Dropdown options={currencies}
                              optionLabel="code"
                              optionValue="code"
                              id={"currency"}
                              emptyMessage={"Még nincs pénznem..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  currency: e.value
                                })
                              }}
                              value={formData.currency}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-xl-6"}>
              <Field name="price" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="price"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Érték</label>
                  <span className="p-d-block">
                      <InputText id="price" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     price: e.target.value
                                   })
                                 }}
                                 value={formData.price}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-xl-6"}>
              <Field name="unit" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unit"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség egység</label>
                  <span className="p-d-block">
                              {formData && formData.unit && formData.unit.id ? (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          value={formData.unit.id}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />)}
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-xl-6"}>
              <Field name="validFrom" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="validFrom"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Érvényesség kezdete</label>
                  <span className="p-d-block">
                    <InputMask id={"validFrom"}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               value={formData.validFrom}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFormData({
                                     ...formData,
                                     validFrom: e.value
                                   })
                                 }
                               }}
                    />
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-xl-6"}>
              <Field name="validUntil" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="validUntil"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Érvényesség vége</label>
                  <span className="p-d-block">
                    <InputMask id={"validUntil"}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               value={formData.validUntil}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFormData({
                                     ...formData,
                                     validUntil: e.value
                                   })
                                 }
                               }}
                    />
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-xl-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-xl-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(PricelistitemFormComponent);
